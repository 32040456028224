<template>
  <div :class="[stopper({ color }), { uppercase: !isLowerText }]">
    <Icon
      v-if="iconName"
      :name="iconName"
      :class="iconClassName"
      :size="iconHeight"
    />
    {{ text }}
  </div>
</template>

<script setup lang="ts">
import { cva, cx, type VariantProps } from 'cva'
import { STOPPER_COLORS } from '@/constants'

withDefaults(defineProps<Props>(), {
  color: STOPPER_COLORS.RED,
  iconName: '',
  iconClassName: '',
  iconWidth: 0,
  iconHeight: 0,
  text: '',
  isLowerText: false,
})

const classes = {
  base: cx([
    'flex',
    'items-center',
    'min-w-max',
    'py-[4.5px]',
    'pl-[6px]',
    'pr-[8px]',
    'rounded-md',
    'font-bold',
    'text-[10px]/[11px]',
    'text-primary-on-color',
  ]),
}

const stopper = cva(classes.base, {
  variants: {
    color: {
      [STOPPER_COLORS.BLUE]: 'bg-gradient-stopper-buyers-choice',
      [STOPPER_COLORS.GREEN]: 'bg-gradient-stopper-powerful-battery',
      [STOPPER_COLORS.YELLOW]: 'bg-gradient-stopper-good-camera',
      [STOPPER_COLORS.VIOLET]: 'bg-gradient-stopper-fast-delivery',
      [STOPPER_COLORS.RED]: 'bg-bg-red-error',
      [STOPPER_COLORS.LIGHT_BLUE]: 'bg-main-blue-1000',
    },
  },
})

export type StopperProps = VariantProps<typeof stopper>

interface Props {
  color: StopperProps['color']
  iconName?: string
  iconClassName?: string
  iconWidth?: number
  iconHeight?: number
  text: string
  isLowerText?: boolean
}
</script>