import mixpanel from 'mixpanel-browser'
import lodash from 'lodash'
import type {
  Device,
  ServiceCenter,
  PaymentMethod,
  Model,
  ModelGroup,
} from '~/types/models'

export function initMixpanel() {
  const config = useRuntimeConfig()

  mixpanel.init(config.public.mixpanelToken as string, {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  })
}

export const emitMixpanelEvent = (name: string) => mixpanel.track(name)

/* --------- ПОДПИСКА START --------- */
// Корректно сохранен контакт в crmfix (просто номер)
export const emitMixpanelEventSubscriptionOrderCreated = (period: number) =>
  mixpanel.track('subscription_order_created', { period })

// Открылась форма ввода паспортных данных
export const emitMixpanelEventSubscriptionPassportFormOpened = () =>
  mixpanel.track('subscription_passport_form_opened')

// Открылось модальное окно подтверждения, отправилось смс
export const emitMixpanelEventSubscriptionSmsModalOpened = () =>
  mixpanel.track('subscription_sms_modal_opened')

// В заявку добавлено ФИО, открылась форма верификации
export const emitMixpanelEventSubscriptionOrderUpdated = () =>
  mixpanel.track('subscription_order_updated')

// Паспортные данные сохранились
export const emitMixpanelEventSubscriptionPassportDataSaved = () =>
  mixpanel.track('subscription_passport_data_saved')

// Заявка подтверждена по СМС
export const emitMixpanelEventSubscriptionSmsVerifySuccess = () =>
  mixpanel.track('subscription_sms_verify_success')

// Неверно введен код подтверждения
export const emitMixpanelEventSubscriptionSmsVerifyFailed = () =>
  mixpanel.track('subscription_sms_verify_failed')

// Клик / тап на рекомендованное более дешёвое устройство
export const emitMixpanelEventSubscriptionRecommendedCheaperDeviceClicked
  = () => mixpanel.track('subscription_recommended_cheaper_device_clicked')
/* --------- ПОДПИСКА END --------- */

/* --------- КОРЗИНА START --------- */
// Добавлен товар в корзину
export const emitMixpanelEventCartAdd = (device: Device) => {
  mixpanel.track('cart_add', {
    device_full_name: device.FullName,
    device_name: device.ModelName,
    device_memory: device.ModelMemory.Name,
    device_color: device.Color.Name,
    device_condition: device.ModelCondition.Name,
  })
}

// Открыта корзина
export const emitMixpanelEventCartOpened = (isEmptyCart: boolean) => {
  mixpanel.track('cart_opened', {
    status: isEmptyCart ? 'with_device' : 'empty',
  })
}

// Оформление заявки (перед подтверждением кода)
export const emitMixpanelEventCartProceededToCheckout = (
  device: Device,
  isPromocodeApplied: boolean,
) => {
  mixpanel.track('cart_proceeded_to_checkout', {
    model: device.FullName,
    is_promocode_applied: isPromocodeApplied,
  })
}

// Изменен филиал
export const emitMixpanelEventCartBranchSelected = (
  serviceCenter: ServiceCenter,
  device: Device,
  isPromocodeApplied: boolean | null,
) => {
  mixpanel.track('cart_branch_selected', {
    service_center_id: serviceCenter.id,
    service_center_address: serviceCenter.full_address,
    model: device.FullName,
    is_promocode_applied: isPromocodeApplied,
  })
}

// Изменен способ оплаты
export const emitMixpanelEventCartPaymentMethodSelected = (
  serviceCenter: ServiceCenter,
  device: Device,
  isPromocodeApplied: boolean,
  paymentMethod: PaymentMethod,
) => {
  mixpanel.track('cart_payment_method_selected', {
    model: device.FullName,
    service_center_id: serviceCenter.id,
    service_center_address: serviceCenter.full_address,
    is_promocode_applied: isPromocodeApplied,
    payment_type: paymentMethod.key,
  })
}

// Создана заявка в crmfix
export const emitMixpanelEventOrderCreated = (
  device: Device,
  serviceCenter: ServiceCenter,
  isPromocodeApplied: boolean,
  paymentMethod: PaymentMethod,
) => {
  mixpanel.track('order_created', {
    model: device.FullName,
    service_center_id: serviceCenter.id,
    service_center_address: serviceCenter.full_address,
    is_promocode_applied: isPromocodeApplied,
    payment_type: paymentMethod.key,
  })
}

// Заявка подтверждена по СМС
export const emitMixpanelEventOrderVerified = (
  device: Device,
  serviceCenter: ServiceCenter,
  paymentMethod: PaymentMethod,
  isPromocodeApplied: boolean,
) => {
  mixpanel.track(
    'order_verified',
    {
      device_full_name: device.FullName,
      device_name: device.ModelName,
      device_memory: device.ModelMemory.Name,
      device_color: device.Color.Name,
      device_condition: device.ModelCondition.Name,
      service_center_id: serviceCenter.id,
      service_center_address:
        serviceCenter.address ?? serviceCenter.full_address,
      payment_type: paymentMethod.key,
      is_promocode_applied: isPromocodeApplied,
    },
    {
      send_immediately: true,
    },
  )
}

// Переход на подписку
export const emitMixpanelEventSubscriptionCart = () =>
  mixpanel.track('podpiska-korzina')
/* --------- КОРЗИНА END --------- */

/* --------- Trade-In START --------- */
// Открыта страница трейд-ин
export const emitMixpanelEventTradeInVisited = () =>
  mixpanel.track('trade_in_visited')
/* --------- Trade-In END --------- */

/* --------- Каталог START --------- */
// Перешел на каталог, фильтры по урлу синхронизировались
export const emitMixpanelEventChooseCatalog = (
  modelGroup: ModelGroup | null,
  model: Model | null,
  memories: string[] | null,
  colors: string[] | null,
  isNew: number | null,
  sortBy: string | null,
) => {
  mixpanel.track('choose_catalog', {
    brand_name: modelGroup?.name,
    model_name: model?.name,
    device_memory: memories,
    device_color: colors,
    device_condition: null,
    subscription_available: !lodash.isNull(isNew),
    sort: sortBy,
  })
}

// Выбран один из фильтров
export const emitMixpanelEventChooseFilter = (
  name: string,
  value: string,
  isNew: number | null,
  sortBy: string | null,
) => {
  mixpanel.track('choose_filter', {
    filter_name: name,
    toggled_value: value,
    subscription_available: !lodash.isNull(isNew),
    sort: sortBy,
  })
}

// Увидел заглушку "Эта модель полностью распродана"
export const emitMixpanelEventCatalogNoticedOutOfStock = () =>
  mixpanel.track('catalog_noticed_out_of_stock')

// Клик по кнопке "Выбрать другое уст-во" на заглушке
export const emitMixpanelEventChooseAnotherDevice = () =>
  mixpanel.track('vybr_ustroystva_zaglushka')
/* --------- Каталог END --------- */

/* --------- Карточка товара START --------- */
// Открытка карточка товара
export const emitMixpanelEventCardOpened = (device: Device) => {
  mixpanel.track('card_opened', {
    device_full_name: device.FullName,
    device_name: device.ModelName,
    device_memory: device.ModelMemory.Name,
    device_color: device.Color.Name,
    device_condition: device.ModelCondition.Name,
    with_video: Boolean(device.Videos.length),
  })
}

// Воспроизведено видео в блоке с фото товара
export const emitMixpanelEventCardVideoPlayed = (device: Device) => {
  mixpanel.track('card_video_played', {
    device_full_name: device.FullName,
  })
}

// Клик / тап по видео про подписку
export const emitMixpanelEventCardSubscriptionVideoPreviewClicked = (
  device: Device,
) => {
  mixpanel.track('card_subscription_video_preview_clicked', {
    device_full_name: device.FullName,
  })
}

// Увеличить изображение в мобилке
export const emitMixpanelEventCardMobilePhotoZoom = () =>
  mixpanel.track('card_mobile_photo_zoom')
/* --------- Карточка товара END --------- */

/* --------- Новые START --------- */
// Клик, выбор другого цвета в карточке товара
export const emitMixpanelEventCardColorSelectionClick = (
  color: string,
  device: Device,
) => {
  mixpanel.track('card_color_selection_click', {
    color,
    device_full_name: device.FullName,
  })
}

// Клик, выбор другого объема памяти в карточке товара
export const emitMixpanelEventCardMemorySelectionClick = (
  color: string,
  device: Device,
) => {
  mixpanel.track('card_memory_selection_click', {
    color,
    device_full_name: device.FullName,
  })
}

// Товар в корзине распродан
export const emitMixpanelEventCartDeviceAlreadySold = () =>
  mixpanel.track('cart_device_already_sold')

// Цена на товар в корзине изменилась
export const emitMixpanelEventCartDevicePriceChanged = () =>
  mixpanel.track('cart_device_price_changed')

// Клик на баннер трейд-ин над шапкой
export const emitMixpanelEventBannerAboveHeaderClick = () =>
  mixpanel.track('banner_above_header_click')

// Клик / тап на кнопку "Подробнее о подписке"
export const emitMixpanelEventCardSubscriptionMoreLinkClicked = (
  device: Device,
) => {
  mixpanel.track('card_subscription_more_link_clicked', {
    device_full_name: device.FullName,
    device_name: device.ModelName,
    device_memory: device.ModelMemory.Name,
    device_color: device.Color.Name,
    device_condition: device.ModelCondition.Name,
  })
}

// Клик по кнопке "Подробнее" в закрепе
export const emitMixpanelEventCardFloatingSubscriptionMoreLinkClicked = (
  device: Device,
) => {
  mixpanel.track('podpiska_kartochka_zakrep_podrobnee', {
    device_full_name: device.FullName,
    device_name: device.ModelName,
    device_memory: device.ModelMemory.Name,
    device_color: device.Color.Name,
    device_condition: device.ModelCondition.Name,
  })
}
/* --------- Новые END --------- */