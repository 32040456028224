<template>
  <div
    v-if="isShowPossible"
    :class="[
      classes.wrapper,
      horizontalDirection ? `${classes.wrapperColumn} scrollbar-fix` : '',
    ]"
  >
    <template
      v-for="(badgeItem, index) in badgeItems"
      :key="index"
    >
      <BaseStopper
        v-if="badgeItem.isShow"
        :color="badgeItem.color"
        :icon-name="badgeItem.iconName ? `stoppers:${badgeItem.iconName}` : undefined"
        :icon-width="badgeItem.iconWidth ? badgeItem.iconWidth : undefined"
        :icon-height="badgeItem.iconHeight ? badgeItem.iconHeight : undefined"
        :icon-class-name="badgeItem.iconName ? 'mr-2' : undefined"
        :text="badgeItem.text"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue'
import { cx } from 'cva'
import type { StopperProps } from '../base/Stopper.vue'
import type { Device } from '~/types/models'
import { STOPPER_COLORS } from '@/constants'
import { declension } from '@/utils'

const props = defineProps({
  device: {
    type: Object as PropType<Device>,
    default() { return {} },
    required: true,
  },
  horizontalDirection: {
    type: Boolean,
    default: false,
  },
})

const classes = {
  wrapper: cx([
    'grid',
    'gap-1',
    'grid-flow-row',
    'auto-rows-fr',
    'justify-start',
    'justify-items-start',
    'pointer-events-none',
  ]),
  wrapperColumn: cx([
    'w-full',
    'grid-flow-column',
    'overflow-x-scroll',
  ]),
}

interface BadgeItem {
  text: string
  color: StopperProps['color']
  iconName?: string
  iconWidth?: number
  iconHeight?: number
  isShow: boolean
}

const badgeItems = computed<Array<BadgeItem>>(() => {
  const { BuyersChoice, PowerfulBattery, GoodCamera, LimitedStock, IsNewModel } = props.device.Badges
  const { DeviceCount } = props.device

  const limitedStockText = `Остал${DeviceCount === 1 ? 'ась' : 'ось'} ${DeviceCount} ${declension(DeviceCount, ['штука', 'штуки', 'штук'])}`

  return [
    {
      text: 'Хит подписки',
      color: STOPPER_COLORS.BLUE as StopperProps['color'],
      iconName: 'trophy',
      iconWidth: 12,
      iconHeight: 12,
      isShow: BuyersChoice,
    },
    {
      text: 'Мощная батарея',
      color: STOPPER_COLORS.GREEN as StopperProps['color'],
      iconName: 'thunderbolt',
      iconWidth: 12,
      iconHeight: 12,
      isShow: PowerfulBattery,
    },
    {
      text: 'Хорошая камера',
      color: STOPPER_COLORS.YELLOW as StopperProps['color'],
      iconName: 'camera',
      iconWidth: 12,
      iconHeight: 12,
      isShow: GoodCamera,
    },
    {
      text: 'Быстрая доставка',
      color: STOPPER_COLORS.VIOLET as StopperProps['color'],
      iconName: 'hourglass',
      iconWidth: 12,
      iconHeight: 12,
      isShow: false,
    },
    {
      text: limitedStockText,
      color: STOPPER_COLORS.RED as StopperProps['color'],
      isShow: LimitedStock,
    },
    {
      text: 'Новинка',
      color: STOPPER_COLORS.LIGHT_BLUE as StopperProps['color'],
      isShow: IsNewModel,
    },
  ]
})

const isShowPossible = computed(() => badgeItems.value.filter(badgeItem => badgeItem.isShow).length)
</script>